<template>
  <div class="register">
    <header>
      <cc-logo-header type="register"/>
    </header>
    <div class="content">
      <div class="form-wrapper">
        <div class="title">{{ $t('huan-ying-zhu-ce') }}{{ includesCC && includesDM ? 'Clougence RDP': includesDM? 'CloudDM': 'CloudCanal' }}
<!--          <div class="right">-->
<!--            <div class="login">-->
<!--              {{ $t('yi-you-zhang-hao-qu') }}-->
<!--              <a @click="goLogin">{{ $t('deng-lu') }}</a>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="form">
          <a-form-model :ref="registerFormRef" :label-col="labelCol" :model="registerForm" :rules="registerRuleValidate"
                        :wrapper-col="wrapperCol">
            <a-form-model-item v-if="errorMsg">
              <a-alert show-icon type="error">{{ errorMsg }}</a-alert>
            </a-form-model-item>
            <a-form-model-item v-for="(item,i) in Object.keys(registerForm)" :key="item"
                               :label="registerFormLabel[item]"
                               :prop="item">
              <a-popover trigger="click" v-if="item === 'password'" placement="right">
                <template slot="content">
                  <p class="pass-valid">
                    <cc-iconfont :name="passLengthValid?'valid-success':'valid-error'" size="12"
                                 :color="passLengthValid ? 'green' : 'red'" style="margin-right: 2px;"/>
                    {{ $t('chang-du-wei-832-ge-zi-fu') }}
                  </p>
                  <p class="pass-valid">
                    <cc-iconfont :name="passPhoneValid?'valid-success':'valid-error'" size="12"
                                 :color="passPhoneValid ? 'green' : 'red'" style="margin-right: 2px;"/>
                    {{ $t('mi-ma-bu-neng-bao-han-shou-ji-hao') }}
                  </p>
                  <p class="pass-valid">
                    <cc-iconfont :name="passCharNumValid?'valid-success':'valid-error'" size="12"
                                 :color="passCharNumValid ? 'green' : 'red'" style="margin-right: 2px;"/>
                    {{ $t('bi-xu-bao-han-ying-wen-he-shu-zi') }}
                  </p>
                </template>
                <a-input v-model="registerForm[item]" :placeholder="$t('qing-shu-ru-registerformlabelitem', [registerFormLabel[item]])"
                         :style="{width: `${item === 'verifyCode' ? 200 : 330}px`}"
                         type="password"
                         autocomplete="new-password" size="large" @pressEnter="handleEnter(i)"/>
              </a-popover>
              <a-input v-model="registerForm[item]" :placeholder="$t('qing-shu-ru-registerformlabelitem', [registerFormLabel[item]])" v-else
                       :style="{width: `${item === 'verifyCode' ? 200 : 330}px`}"
                       :type="item === 'password' || item === 'rePassword' ? 'password' : 'text'"
                       autocomplete="new-password" size="large" @pressEnter="handleEnter(i)"/>
              <cc-sms-button v-if="item === 'verifyCode'" :phoneNumber="registerForm.phone"
                             :verifyCodeType="VERIFY_CODE_TYPE.REGISTER"
                             :verifyType="VERIFY_TYPE.SMS"
                             class="register-sms-button"
                             size="large"
                             style="margin-left: 10px;width: 120px;"/>
            </a-form-model-item>
            <a-form-model-item label=" ">
              <a-button class="register-btn" size="large" style="width: 330px"
                        type="primary"
                        @click.native="handleSubmit">{{ $t('zhu-ce') }}
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <footer>
      <cc-footer/>
    </footer>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import CCLogoHeader from '@/components/CCLogoHeader';
import CCFooter from '@/components/CCFooter';
import { VERIFY_CODE_TYPE, VERIFY_TYPE } from '@/const';
import { mapGetters } from 'vuex';

export default {
  name: 'Register',
  components: {
    CcFooter: CCFooter,
    CcLogoHeader: CCLogoHeader
  },
  computed: {
    ...mapGetters([
      'includesCC',
      'includesDM'
    ])
  },
  data() {
    const validateConfirmPassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('qing-zai-ci-shu-ru-ni-de-mi-ma')));
      } else if (value !== this.registerForm.password) {
        callback(new Error(this.$t('liang-ci-mi-ma-bu-yi-zhi')));
      } else {
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (value === '') {
        callback(new Error(this.$t('qing-zai-ci-shu-ru-ni-de-mi-ma')));
      } else if (value !== this.registerForm.password) {
        callback(new Error(this.$t('liang-ci-mi-ma-bu-yi-zhi')));
      } else if (this.registerForm.phone && value.includes(this.registerForm.phone)) {
        callback(new Error(this.$t('mi-ma-bu-neng-bao-han-shou-ji-hao')));
      } else if (!reg.test(value)) {
        callback(new Error(this.$t('bi-xu-bao-han-ying-wen-he-shu-zi')));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      VERIFY_CODE_TYPE,
      VERIFY_TYPE,
      registerFormRef: 'registerFormRef',
      registerLoading: false,
      timer: null,
      errorMsg: '',
      registerForm: {
        phone: '',
        verifyCode: '',
        email: '',
        password: '',
        rePassword: '',
        userName: '',
        company: ''
      },
      registerFormLabel: {
        phone: this.$t('shou-ji-hao-ma'),
        verifyCode: this.$t('duan-xin-yan-zheng-ma'),
        email: this.$t('you-xiang'),
        password: this.$t('mi-ma'),
        rePassword: this.$t('que-ren-mi-ma'),
        userName: this.$t('xing-ming'),
        company: this.$t('gong-si-ming-cheng')
      },
      registerRuleValidate: {
        phone: [
          {
            required: true,
            message: this.$t('shou-ji-hao-bu-neng-wei-kong')
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: this.$t('shou-ji-hao-ma-ge-shi-bu-zheng-que'),
            trigger: 'blur'
          }
        ],
        verifyCode: [
          {
            required: true,
            message: this.$t('yan-zheng-ma-bu-neng-wei-kong')
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('you-xiang-bu-neng-wei-kong'),
            trigger: 'blur'
          }, {
            type: 'email',
            message: this.$t('you-xiang-ge-shi-cuo-wu'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('mi-ma-bu-neng-wei-kong'),
            trigger: 'blur'
          },
          {
            min: 8,
            message: this.$t('mi-ma-chang-du-wei-832'),
            trigger: 'blur'
          },
          {
            max: 32,
            message: this.$t('mi-ma-chang-du-wei-832'),
            trigger: 'blur'
          },
          {
            validator: validatePassCheck,
            trigger: 'blur'
          }
        ],
        rePassword: [
          {
            required: true,
            message: this.$t('que-ren-mi-ma-bu-neng-wei-kong'),
            trigger: 'blur'
          },
          {
            validator: validateConfirmPassCheck,
            trigger: 'blur'
          }
        ],
        userName: [
          {
            required: true,
            message: this.$t('xing-ming-bu-neng-wei-kong')
          }
        ],
        company: [
          {
            required: true,
            message: this.$t('gong-si-ming-cheng-bu-neng-wei-kong')
          }
        ]
      },
      passCharNumValid: false,
      passLengthValid: false,
      passPhoneValid: false
    };
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs[this.registerFormRef].validate();
      if (isValid) {
        const data = cloneDeep(this.registerForm);
        delete data.rePassword;
        data.country = 'China';
        data.verifyType = 'SMS_VERIFY_CODE';
        const res = await this.$services.register({
          data,
          msg: this.$t('zhu-ce-cheng-gong')
        });
        if (res.success) {
          setTimeout(async () => {
            await this.$router.push({ name: 'Login' });
          }, 1000);
        }
      }
    },
    handleEnter(i) {
      if (i === Object.keys(this.registerForm).length - 1) {
        this.handleSubmit();
      }
    },
    goLogin() {
      this.$router.push({ path: '/login' });
    }
  },
  watch: {
    'registerForm.password': {
      handler(newValue) {
        this.passLengthValid = !(newValue.length < 8 || newValue.length > 32);

        this.passPhoneValid = !(this.registerForm.phone && newValue.includes(this.registerForm.phone));

        const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);

        this.passCharNumValid = reg.test(newValue);
      }
    }
  }
};
</script>

<style lang="less">
.register {
  background-color: #EDEDED;

  .ant-form .ant-form-item {
    margin-bottom: 20px;

    .ant-form-explain {
      bottom: -20px;
    }
  }

  .right {
    position: absolute;
    right: 20px;
    bottom: 12px;
    display: flex;

    .login {
      font-size: 14px;

      a {
        font-size: 14px;
      }

      span {
        color: @primary-color;
      }
    }
  }

  .content {
    position: absolute;
    top: 80px;
    bottom: 50px;
    background-color: #EDEDED;
    .width-full();
    display: flex;
    justify-content: center;

    .form-wrapper {
      background: white;
      overflow: scroll;
      min-width: 1000px;
      max-width: 1000px;
      position: relative;

      .title {
        color: #333;
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
        position: relative;
      }

      .form {
        margin-top: 25px;
        margin-left: 240px;

        .ivu-form-item-content {
          display: flex;

          .ivu-form-item-error-tip {
            position: relative;
            padding-top: 0;
            line-height: 40px;
            margin-left: 10px;
          }
        }
      }
    }

    .register-btn span {
      font-size: 16px;
      font-family: PingFangSC-Semibold;
      font-weight: 500;
    }
  }

  footer {
    .width-full();
    background-color: #EDEDED;
    position: absolute;
    bottom: 0;
  }
}

.pass-valid {
  line-height: 20px;
  line-height: 20px;
}
</style>
